import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import { Link, navigate } from "gatsby"
import image from "../../assets/images/homepage/la-nostra-comunitat/img-1@3x.png"
import Button from "../button-generic"
import CategoryItem from "../categories/category-tag-item"
import Utils from "../../utils"
import Icon from "../icon"

 const TestimonyItem = (props) => {

   const {
     title,
     day,
     month,
     description,
     datehour,
     place,
     url,
     className,
     date,
     path,
     image,
     includeTags,
     categories,
     includeSource,
     articleSource,
     articleSourceUrl,
     nodeType,
     articleSourceDate,
     langcode,
     imageSrc
   } = props

   const { t } = useContext(LanguageContext)

   // Function to construct the new path
  const constructPath = (langcode, path) => {
    if (langcode === 'ca') {
        return path;
    } else if (langcode === 'en' || langcode === 'es') {
        return `/${langcode}${path}`;
    }
  };

   return (
           <div className={className}>
              <Link to={constructPath(langcode, path)} className="blog-item-link">
        <article
          className="blog-item-content testimony-item"
          itemScope
          itemType="http://schema.org/Article"
        >
          <div style={{ maxWidth: '294px', height: '192px', width: '100%' }} className="m-b-16 medium-p-r-24 blog-item-image-wrapper">
            <img
              src={imageSrc} // Fallback to a default image if imageSrc is not provided
              alt={title}
              className="article-image"
              style={{ width: '100%', height: '192px', objectFit: 'cover' }} // Make sure the image covers the area
            />
            <Icon icon="icons-32-x-32-export-play" className="play-icon" />
          </div>
          <header className="p-l-16 medium-p-0">
            <small className="date m-b-16">{date}</small>
            <h2 className="blog-item-title m-b-5 m-b-16">
              <span itemProp="headline"> {title} </span>
            </h2>
          </header>
          <section>
            <div className="blog-item-desc m-b-16 h5-light-l-black p-l-16 medium-p-0">
              {props.description ? Utils.trimToN(props.description, 75, 3) : ''}
            </div>
            <Link to={constructPath(langcode, path)} className="r-more p-l-16 medium-p-0">
              {t("Saber més")}
            </Link>
            {includeTags && categories && categories[0] && (
              <div className="category-item p-l-16 m-t-16">
                {/* Render your category item here */}
                {categories[0].name}
              </div>
            )}
          </section>
        </article>
      </Link>
           </div>
   )
 }

 export default TestimonyItem
