import React, { useContext, useState, useEffect } from "react"
import { LanguageContext } from "../../context"
import Banner from "../banner"
import TestimoniesList from "../blog/testimonies-list"
import Button from "../button-visit-us"
import Button2 from "../button-generic"
import { navigate, useStaticQuery, graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import PageTopContent from "../home-top-content"
import { useFlexSearch } from "react-use-flexsearch"
import { useLunr } from "react-lunr"
import { Index } from "lunr"
import Filters from "../search/filters"
import Section from "../section"
import ServiceItem from "../services/service-item"
import Pagination from "../pagination"


const getYoutubeThumbnail = (url) => {
  return new Promise((resolve, reject) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?\s]*).*/;
    const match = url.match(regExp);
    if (match && match[7].length === 11) {
      const videoId = match[7];
      const highResUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
      const standardResUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

      const img = new Image();
      img.onload = () => resolve(highResUrl); // High resolution image is available
      img.onerror = () => resolve(standardResUrl); // Fallback to standard resolution
      img.src = highResUrl;
    } else {
      resolve(null); // Resolve with null if URL is invalid
    }
  });
};

const TestimoniesPage = (props) => {

  const {
    post,
    bannerHeight="408px",
    //localSearchPages,
    blogPosts,
    pageContext,
    categories,
    pageInfo,
    initialQuery,
    refreshList
  } = props



  const { t } = useContext(LanguageContext)



  const allBlogPosts = blogPosts || [];
  const [list, setList] = useState([]);

  useEffect(() => {
    const resolveThumbnails = async (posts) => {
      const updatedPosts = await Promise.all(posts.map(async (post) => {
        const videoUrl = post?.relationships?.field_testimony_video?.field_media_oembed_video;
        const imageUrl = post?.relationships?.field_testimoni_image?.relationships?.field_media_image?.image_style_uri?._1103x488;
  
        // Default to the static image URL if available
        let imageSrc = imageUrl || ''; // imageUrl is the default if present
  
        // Resolve video thumbnail only if videoUrl is valid
        if (videoUrl) {
          try {
            const videoId = new URL(videoUrl, "https://www.youtube.com").searchParams.get("v");
            if (videoId) {
              const highResUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
              const standardResUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
  
              // Attempt to load high-resolution thumbnail
              const response = await fetch(highResUrl, { mode: 'no-cors' });
              imageSrc = response.ok ? highResUrl : standardResUrl;
            } else {
              // If videoId couldn't be found, keep the imageSrc as imageUrl
              imageSrc = imageUrl || ''; // Or use a generic placeholder if you prefer
            }
          } catch (error) {
            console.error("Error fetching YouTube thumbnail:", error);
            // If an error occurs (CORS or otherwise), fallback to the standard thumbnail or imageUrl
            imageSrc = imageUrl || ''; // Or use a generic placeholder if you prefer
          }
        }
  
        return { ...post, imageSrc };
      }));
  
      setList(updatedPosts);
    };
  
    if (allBlogPosts.length) {
      resolveThumbnails(allBlogPosts);
    }
  }, [allBlogPosts]);
  

  // State for the list
  //const [list, setList] = useState([...list?.slice(0, 12)]);

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(list.length > 12);
  const [testimoniesWithThumbnails, setTestimoniesWithThumbnails] = useState([]);
  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < list.length;
      const nextResults = isMore
        ? list.slice(currentLength, currentLength + 12)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
      setShowMessage(true)
    }
  }, [loadMore, hasMore]); //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < list.length;
    setHasMore(isMore);
  }, [list]); //eslint-disable-line



  return(
    <div className="page-blog page-testimonies">
    <Banner
      post={post}
      height={bannerHeight}
      backgroundColor="bordeaux"
    />

     <section className="section-blog-list-filters hide">
     <div className="container p-0">
      <Filters
           buttons={[
             <Button2
              className="btn-all-blog-posts p-0"
              title={t("Tots els Posts")}
              wrapperClass="col-2- p-l-0 p-r-0 all-ministries-wrapper m-r-32"
              action="/testimonis"
              />,
             <Button2
              className="btn-dropdown filter-blog-post"
              type="dropdown"
              title={t("Totes les Categories")}
              options={categories}
              wrapperClass="col-2- p-l-0 p-r-0 all-dropdown-wrapper"
              action="/testimonis"
              />,
            ]}
           //searchQuery={searchQuery}
           //setSearchQuery={setSearchQuery}
           action="/testimonis"
           marginbottom="m-b-43"
           marginTop="m-t-28"
           hasSearch={true}
           searchPlaceholder="Cerca per post"
           initialQuery={initialQuery}
           refreshList={refreshList}
       />
       </div>
      </section>

       <TestimoniesList
        blogPosts={list}
        lang={pageContext?.langcode}
        className="blog-list m-b-108 p-t-80"
        classContainer="container p-0"
        classRow="col-12 col-md-3"
        includeTags={true}
       />
      {!initialQuery &&
       <section className="section-pagination m-b-80">

         <Pagination
          pageInfo={pageInfo}
          alias={post?.path?.alias}
        />
       </section>
       }

       <div className="container">
        <div className="d-flex justify-content-center m-b-40">
           {hasMore ? (
            <button
              onClick={handleLoadMore}
              className="btn btn-outline bordeaux"
            >Carregar més testimonis</button>
          ) : (
            <p>{showMessage ? "No s'han trobat més resultats" : ""}</p>
          )}
        </div>
      </div>
     </div>

  )
}



export const unFlattenResults = (results) =>{
  return results.map(post => {
    const { summary, alias, title } = post;
      return { alias, body: { title, summary }
    };
  });
}



 export default TestimoniesPage
